import React, { Component } from 'react'

class PayDay extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
}
export default PayDay
